import React, { Component, cloneElement, Children } from 'react';
import PropTypes from 'prop-types';

class LineGraph extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    height: PropTypes.number,
    width: PropTypes.number,
    fonts: PropTypes.string,
    minY: PropTypes.number
  };

  componentDidMount() {
    this.updateChart(this.props);
  }

  componentDidUpdate() {
    this.updateChart(this.props);
  }

  updateChart(config) {
    const canvas = this.canvas;
    const ctx = canvas.getContext('2d');
    const { data, height, width } = config;

    ctx.clearRect(0, 0, width, height);

    this.addLabels(ctx);

    // get total topYield
    const topYield = Math.max(this.props.minY || 0, ...data.datasets.map((set) => Math.max(...set.data))) + 1;

    data.datasets.forEach((dataset, i) => {
      this.addArea(ctx, dataset, topYield);
      this.addLine(ctx, dataset, topYield);
      this.addFinalValue(ctx, dataset, topYield);
    });
  }

  addLabels(ctx) {
    const { data, height, width, fonts = '12px Arial' } = this.props;
    const length = data.labels.length;
    const workWidth = width - data.padding.left - data.padding.right;
    const spaces = workWidth / length;

    //Check if we can fit all labels, otherwise show only odd numbers
    ctx.font = fonts;
    const textSize = ctx.measureText('30');
    const showOnlyOddNumbers = textSize.width > spaces;

    data.labels.forEach((val, i) => {
      if (showOnlyOddNumbers && i % 2) {
        return;
      }
      ctx.font = fonts;
      ctx.fillStyle = '#000';
      ctx.fillText(val, data.padding.left + i * spaces, height - data.padding.bottom - 12);
    });
  }

  addLine(ctx, dataset, topYield) {
    const { data, height, width } = this.props;
    const length = data.labels.length;
    const workWidth = width - data.padding.left - data.padding.right;
    const spaces = workWidth / length;

    ctx.strokeStyle = dataset.borderColor;
    ctx.lineWidth = 2;

    ctx.shadowColor = dataset.backgroundColor;
    ctx.shadowBlur = 3;
    ctx.shadowOffsetX = 1;
    ctx.shadowOffsetY = 2;

    // move to first point..
    const firstPoint = dataset.data[0];
    const line = new Path2D();

    line.moveTo(0, height - height * (firstPoint / topYield));

    // Iterate over rest and move there
    dataset.data.forEach((item, i) => {
      const x = data.padding.left + i * spaces;
      const y = height - height * (item / topYield);
      const nx = data.padding.left + (i + 1) * spaces;
      const ny = height - height * (dataset.data[i + 1] / topYield);
      const mx = (x + nx) / 2;
      const my = (y + ny) / 2;

      var cp_x1 = (mx + x) / 2;
      var cp_x2 = (mx + nx) / 2;

      line.quadraticCurveTo(cp_x1, y, mx, my);
      line.quadraticCurveTo(cp_x2, ny, nx, ny);
    });

    ctx.stroke(line);
  }

  addArea(ctx, dataset, topYield) {
    const { data, height, width } = this.props;
    const length = data.labels.length;
    const workWidth = width - data.padding.left - data.padding.right;
    const spaces = workWidth / length;

    let region = new Path2D();

    // move to first point..
    const firstPoint = dataset.data[0];
    const lastPoint = dataset.data[dataset.lenght - 1];

    region.moveTo(0, height);
    if (firstPoint !== 0) {
      region.lineTo(0, height - height * (firstPoint / topYield));
    }

    // Iterate over rest and move there
    dataset.data.forEach((item, i) => {
      const x = data.padding.left + i * spaces;
      const y = height - height * (item / topYield);
      const nx = data.padding.left + (i + 1) * spaces;
      const ny = height - height * (dataset.data[i + 1] / topYield);
      const mx = (x + nx) / 2;
      const my = (y + ny) / 2;
      const cp_x1 = (mx + x) / 2;
      const cp_x2 = (mx + nx) / 2;

      region.quadraticCurveTo(cp_x1, y, mx, my);
      region.quadraticCurveTo(cp_x2, ny, nx, ny);
    });

    region.lineTo(dataset.data.length * spaces, height);

    region.closePath();

    var grd = ctx.createLinearGradient(0, 0, data.padding.left + dataset.data.length * spaces, 0);
    grd.addColorStop(0.3, dataset.backgroundColor);
    grd.addColorStop(1, dataset.backgroundColorEnd);

    ctx.fillStyle = grd; //dataset.backgroundColor;
    ctx.fill(region, 'nonzero');
  }

  addFinalValue(ctx, dataset, topYield) {
    const { data, height, width, fonts = '12px Arial' } = this.props;
    const length = data.labels.length;
    const workWidth = width - data.padding.left - data.padding.right;
    const dataLength = dataset.data.length;

    if (dataLength === 0) {
      return;
    }

    const spaces = workWidth / length;

    const finalItem = dataset.data[dataLength - 1];

    const y = height - height * (finalItem / topYield) + 20;
    const x = dataLength * spaces - 5;
    const finalYieldText = `${finalItem.toFixed(1)}%`;

    ctx.save();

    ctx.font = '12px Arial';
    ctx.fillStyle = '#000';
    ctx.strokeStyle = '#000';
    ctx.textAlign = 'right';

    ctx.fillText(finalYieldText, x, y);

    ctx.restore();
  }

  render() {
    const { height, width } = this.props;
    return <canvas width={width} height={height} ref={(ref) => (this.canvas = ref)}></canvas>;
  }
}

export default LineGraph;
