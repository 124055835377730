import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { CYCLE_STAGES } from '../../common/actions/cycle.js';

import continueIcon from '../../assets/continue.svg';

const renderDate = function (date) {
  const minutes = date.getMinutes();

  return `${date.getDate()}/${date.getMonth() + 1} ${date.getHours()}:${
    minutes < 10 ? '0' + minutes : minutes
  }`;
};

function CycleList(props) {
  const { clients, cycles, onCycleSelect } = props;

  return (
    <section className="cycle-banner">
      <div className="list-table cycle-items">
        <div className="table-head">
          <div>Date</div>
          <div>Lot</div>
          <div>Client</div>
          <div>Yield</div>
        </div>
        {cycles.map((item) => {
          const client = clients.find((cl) => cl._id === item.clientId);
          const clientName = client ? client.name : 'Unknown';
          const yeildGoal = client.config.yieldgoal || 100;
          const date = new Date(item.createdAt);
          const yieldClass = item.data.yield >= yeildGoal ? 'yield-goal' : '';
          return (
            <div
              key={item._id}
              onClick={() => {
                onCycleSelect(item, client);
              }}
              className="table-bar table-no-bar"
            >
              <div>{renderDate(date)}</div>
              <div className="table-row-15">{item.data.lotnumber}</div>
              <div className="table-row-35">{clientName}</div>
              <div className={`table-bar-right ${yieldClass}`}>
                {item.state === CYCLE_STAGES.FINISHED ? (
                  `${item.data.yield}%`
                ) : (
                  <Link to={`/register/${item.clientId}/${item._id}`}>
                    <img height="16" src={continueIcon} />
                  </Link>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
}

CycleList.propTypes = {
  clients: PropTypes.array,
  cycles: PropTypes.array,
  onCycleSelect: PropTypes.func,
};


export default CycleList;
