import Auth from '../auth.js';
import { post, get, put } from '../utils/http.js';
import { baseApi } from '../config.js';

import { HYDRATE_CLIENTS } from './clients.js';

export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_HYDRATE = 'USER_HYDRATE';

export const hydrateUserData = () => {
  return (dispatch) => {
    dispatch({
      type: USER_HYDRATE_WORKING,
    });

    return get(`${baseApi}account/profile`)
      .then((data) => data.json())
      .then((data) => {
        dispatch({
          type: USER_HYDRATE,
          data,
        });
      });
  };
};

export const onLogout = (opt) => {
  return (dispatch) => {
    return Auth.logOut().then(() => {
      dispatch({ type: USER_LOGOUT, data: {}, status: false });
    });
  };
};

export const forgotPassword = (model) => {
  return post(`${baseApi}/local/resetcode`, model);
};

export const resetPassword = (model) => {
  return post(`${baseApi}/local/reset/code`, model);
};

export const changePassword = (model) => {
  return post(`${baseApi}/local/reset`, model);
};

export const loginUser = (data) => {
  return (dispatch) => {
    dispatch({
      type: USER_LOGIN,
      data,
      status: false,
    });
    dispatch({
      type: HYDRATE_CLIENTS,
      data: data.clients,
    });
  };
};

export const checkLogin = () => {
  const authData = Auth.getAuthData();

  if (!authData || !authData.id) {
    return Promise.reject();
  }

  return get(`${baseApi}users/${authData.id}`).then((data) => data.json());
};
