import { useEffect, useState } from "react";
import { baseApi } from "../common/config";
import { get, post } from "../common/utils/http";

export default function useDelivery() {
  const [cycleOptions, setCycleOptions] = useState([]);
  const [cycles, setCycles] = useState([]);
  const [client, setClient] = useState();

  useEffect(() => {
    if (!client) {
      setCycles([]);
      setCycleOptions([]);
      return;
    }

    get(`${baseApi}/delivery/options?client=${client}`)
      .then((data) => {
        setCycleOptions(data);
        setCycles([]);
      });
  }, [client])

  function saveDelivery(){
    return post(`${baseApi}/delivery`, {
        clientId: client,
        cycles: cycles
      })
      .then((data) => {
        console.log(data);
        return data;
      });
  }

  function selectClient(clientId) {
    setClient(clientId);
    setCycles([]);
  }

  function addCycle(cycleId) {
    setCycles([
      ...cycles,
      cycleId
    ]);
  }

  function removeCycle(cycleId) {
    setCycles([
      ...cycles.filter((cycle) => cycle !== cycleId)
    ]);
  }

  function getWeight(){
    return cycleOptions.filter((cycle) => cycles.includes(cycle._id))
      .reduce((sum, cycle) => {
        return sum + cycle.data.aluminium.reduce((tot, al) => tot + al, 0)
      }, 0);
  }

  return {
    cycleOptions,
    cycles,
    client,
    selectClient,
    addCycle,
    removeCycle,
    getWeight,
    saveDelivery
  }

}