import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { get } from '../../common/utils/http.js';
import { baseApi } from '../../common/config.js';
import CycleList from './cycleslist.js';
import Receipt from '../../receipt/receipt.js';

function CycleListContainer(props) {
  const { clients } = props;

  const [loaded, setLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    get(`${baseApi}/cycles`).then((data) => {
      setLoaded(true);
      setItems(data);
    });
  }, []);

  function toggleCycle(item, client){
    if (item && client) {
      setSelected({item, client});
    } else {
      setSelected(null);
    }
  }

  if (!loaded) {
    return <p>Loading</p>;
  }

  return selected ? (
    <Receipt client={selected.client} cycle={selected.item} onBackClick={toggleCycle} />
  ) : (
    <CycleList cycles={items} clients={clients} onCycleSelect={toggleCycle} />
  );

}

CycleListContainer.propTypes = {
  clients: PropTypes.array,
};

export default CycleListContainer;
