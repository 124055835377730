import React, { Component } from 'react';

import meltman from '../../assets/meltman.png';

class RegisterFinished extends Component {
  render() {
    return (
      <section className="register">
        <h2 style={{ margin: '50px 0', textAlign: 'center' }}>Great job!</h2>
        <img style={{ display: 'block', margin: '0 auto' }} width="500" src={meltman} />
      </section>
    );
  }
}

export default RegisterFinished;
