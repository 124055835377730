import React from 'react';
import PropTypes from 'prop-types';
import { filledPot } from './pot2.js';

const renderDate = function (date) {
  const minutes = date.getMinutes();
  return `${date.getDate()}/${date.getMonth() + 1} ${date.getHours()}:${minutes < 10 ? '0' + minutes : minutes}`;
};

function Receipt(props) {
  const { cycle, client, onBackClick } = props;

  const totalAluminium = cycle.data.aluminium ? cycle.data.aluminium.reduce((sum, al) => sum + al, 0) : 0;
  const totalDross = cycle.data.dross ? cycle.data.dross.reduce((sum, dross) => sum + dross.weight, 0) : 0;
  const volumeWithSalt = totalDross + (cycle.data.salt || 0);
  const yieldPercent = (totalAluminium / volumeWithSalt) * 100;
  const yields = isNaN(yieldPercent) ? 0 : Number(yieldPercent.toFixed(1));
  const yieldClass = yields >= (client.config.yieldgoal || 100) ? 'yield-goal' : '';
  const residue = totalDross - totalAluminium;
  const date = cycle.createdAt ? new Date(cycle.createdAt) : new Date();

  return (
    <section className="register-receipt">
      <div className="list-table">
        <div className="table-head">
          <div>Date</div>
          <div>Lot</div>
          <div>Client</div>
          <div>Yield</div>
        </div>
        <div className="table-bar">
          <div>{renderDate(date)}</div>
          <div className="table-row-15">{cycle.data.lotnumber}</div>
          <div className="table-row-35">{client.name}</div>
          <div className={`table-bar-right ${yieldClass}`}>{yields}%</div>
        </div>
        <div className="table-body">
          <div className="table-row">
            <div className="table-row-half table-row-head">Cycletime</div>
            <div className="table-row-half table-row-content">{cycle.data.cycletime}</div>
          </div>
          <div className="table-row">
            <div className="table-row-half table-row-head">Material</div>
            <div className="table-row-half table-row-content">{cycle.data.material}</div>
          </div>
          {cycle.data.dross.map((dross, i) => (
            <div className="table-row" key={i}>
              <div className="table-row-half table-row-head">{dross.container ? dross.container : 'Dross'} (kg)</div>
              <div className="table-row-half table-row-content">{dross.weight}</div>
            </div>
          ))}
          <div className="table-row">
            <div className="table-row-half table-row-head"> Oil (l)</div>
            <div className="table-row-half table-row-content">{cycle.data.oil || ''}</div>
          </div>
          <div className="table-row">
            <div className="table-row-half table-row-head"> Oxygen (Nm3)</div>
            <div className="table-row-half table-row-content">{cycle.data.oxygen || ''}</div>
          </div>
          <div className="table-row">
            <div className="table-row-half table-row-head">Salt (kg)</div>
            <div className="table-row-half table-row-content">{cycle.data.salt || ''}</div>
          </div>
          <div className="table-row">
            <div className="table-row-half table-row-head">Salt slag (kg)</div>
            <div className="table-row-half table-row-content">{residue || ''}</div>
          </div>
          <div className="table-row" style={{ borderBottom: 'none' }}>
            <div className="table-row-full table-row-head">Aluminium (kg)</div>
          </div>
          <div className="table-row">
            <div className="table-row-full receipt-aluminium-list">
              {!cycle.data.aluminium || cycle.data.aluminium.length === 0 ? (
                <span className="receipt-aluminium"></span>
              ) : (
                cycle.data.aluminium
                  .filter((al) => al > 0)
                  .map((al, i) => {
                    const percent = al / Math.max(...cycle.data.aluminium);
                    return (
                      <div className="receipt-aluminium" key={i}>
                        <span className="receipt-aluminium-number">{al}</span>
                        <div
                          className="receipt-aluminium-icon"
                          dangerouslySetInnerHTML={{
                            __html: filledPot(`pot-${i}`, percent)
                          }}
                        ></div>
                      </div>
                    );
                  })
              )}
            </div>
          </div>
          <div className="table-row">
            <div className="table-row-half table-row-head">Total</div>
            <div className="table-row-half table-row-content">{totalAluminium}</div>
          </div>
          <div className="table-row">
            <div className="table-row-half table-row-head">Yield</div>
            <div className={`table-row-half table-row-content ${yieldClass}`}>{yields}%</div>
          </div>
          {onBackClick && (
            <div className="table-row-button">
              <button className="btn" onClick={onBackClick}>
                Back
              </button>
            </div>
          )}
          <div className="table-row table-row-pusher"></div>
        </div>
      </div>
    </section>
  );
}

Receipt.propTypes = {
  client: PropTypes.object,
  cycle: PropTypes.object,
  onBackClick: PropTypes.func
};

export default Receipt;
