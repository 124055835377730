import range from './range.js';

export const toFixedLength = function (number, length) {
  const start = number.toString();
  if (length - start.length > 0) {
    return (
      range(1, length - start.length)
        .map(() => '0')
        .join('') + start
    );
  }
  return start;
};
