import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { toFixedLength } from '../../common/utils/numbers.js';

class RegisterStage3 extends Component {
  constructor(props) {
    super(props);

    this.onValueChange = this.onValueChange.bind(this);
    this.onAluminiumChange = this.onAluminiumChange.bind(this);
    this.canContinue = this.canContinue.bind(this);
    this.nextStep = this.nextStep.bind(this);
    this.removeAluminium = this.removeAluminium.bind(this);
  }

  static propTypes = {
    clientId: PropTypes.string,
    client: PropTypes.object,
    cycle: PropTypes.object,
    setRegValue: PropTypes.func,
    addAluminium: PropTypes.func,
    rmAluminium: PropTypes.func
  };

  componentDidMount() {
    if (this.props.cycle && this.props.cycle.data.aluminium.length === 0) {
      this.props.addAluminium();
    }
  }

  componentWillUnmount() {}

  onValueChange(...keys) {
    return (e) => {
      const target = e.target;
      const value = target.value;
      const isNumber = target.getAttribute('data-value-type') === 'number'; ///^\d+$/.test(value);
      const finalValue = isNumber ? Number(value) : value;
      this.props.setRegValue({
        keys: keys,
        value: finalValue
      });
    };
  }

  onAluminiumChange(...keys) {
    return (e) => {
      this.onValueChange('aluminium', ...keys)(e);
      const { aluminium } = this.props.cycle.data;
      if (aluminium[aluminium.length - 1] > 0) {
        this.props.addAluminium();
      }
    };
  }

  removeAluminium(index) {
    return (e) => {
      this.props.rmAluminium(index);
    };
  }

  canContinue() {
    const { cycle } = this.props;
    const { aluminium } = cycle.data;

    return aluminium.length !== 0;
  }

  nextStep() {
    if (!this.canContinue()) {
      return;
    }
    this.props.nextStep();
  }

  render() {
    const { client, cycle, addAluminium } = this.props;
    const { dross, aluminium, lotnumber } = cycle.data;

    const totalDross = dross.reduce((sum, item) => sum + item.weight, 0);
    const totalAluminium = aluminium.reduce((sum, item) => sum + item, 0);
    const totalYield = totalAluminium / totalDross;
    const yieldPercent = isNaN(totalYield) ? 0 : Number((totalYield * 100).toFixed(1));
    const yieldClass = yieldPercent >= (client.config.yieldgoal || 100) ? 'yield-goal' : '';

    const lotnum = toFixedLength(lotnumber, 3).split('');

    return (
      <section className="register register-2">
        <div className="register-row">
          <div className="flex-360">
            <div className="form-group">
              <label className="form-control form-numbers">
                <span>Lot number</span>
                <div className="input">{lotnum[0]}</div>
                <div className="input">{lotnum[1]}</div>
                <div className="input">{lotnum[2]}</div>
              </label>
            </div>
          </div>
          <div className="flex-360"></div>
          <div className="flex-360 text-right">
            <button disabled={!this.canContinue()} className="btn btn-primary" type="button" onClick={this.nextStep}>
              Finish cycle
            </button>
          </div>
        </div>
        <div className="register-row">
          <div className="flex-770 box">
            <div className="form-control">
              <span>
                Aluminium
                <button onClick={addAluminium} className="btn btn-square right-btn">
                  +
                </button>
              </span>
            </div>
            <div className="aluminium-flex">
              {aluminium.map((alItem, index) => {
                return (
                  <div className="form-group form-group-siblings" key={index}>
                    <label className="form-control input-group">
                      <input
                        className="input"
                        type="text"
                        pattern="[0-9]*"
                        data-value-type="number"
                        value={alItem}
                        onChange={this.onAluminiumChange(index)}
                      />
                      <span
                        onClick={() => {
                          this.props.rmAluminium(index);
                        }}
                        className="input-type"
                      >
                        kg
                      </span>
                    </label>
                    {index !== 0 && (
                      <button onClick={this.removeAluminium(index)} tabIndex="-1" className="btn btn-square">
                        -
                      </button>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="flex-360">
            <div className="box joi-box">
              <span className="joi-box-header">Total</span>
              <p className="joi-box-content">{totalAluminium}</p>
            </div>

            <div className="box joi-box">
              <span className="joi-box-header">Yield</span>
              <p className={`joi-box-content ${yieldClass}`}>{yieldPercent}%</p>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default RegisterStage3;
