import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router, Switch, Route } from 'react-router-dom';
import Authentication from './common/auth.js';
import { loginUser } from './common/actions/user.js';

import LoginLayout from './login/login.js';
import DefaultLayout from './app-container.js';

// Polyfill for older browsers..
import { AppContainer } from 'react-hot-loader';

import history from './common/utils/history.js';
import store from './common/store.js';

const el = document.getElementById('app');

const renderDom = () => {
  ReactDOM.render(
    <AppContainer>
      <Provider store={store}>
        <Router history={history}>
          <Switch>
            <Route path="/login" component={LoginLayout} />
            <Route path="/" component={DefaultLayout} />
          </Switch>
        </Router>
      </Provider>
    </AppContainer>,
    el
  );
};

Authentication.checkUserLogin()
  .then((data) => {
    history.push('/');
    return store.dispatch(loginUser(data));
  })
  .catch((ex) => {
    history.push('/login');
    console.error(ex);
  })
  .then(() => {
    renderDom();
  });

if (navigator.userAgent.match(/(iPad|iPhone|iPod)/g)) {
  document.body.style.cursor = 'pointer';
}
