import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Close } from '../components/icons.js';
import useReceived from './use-received.js';

function ReceivePage({ clients }){
  const {
    client,
    selectClient,
    weight,
    setWeight,
    material,
    setMaterial,
    container,
    setContainer,
    saveReceived
  } = useReceived();

  const [isSaving, setIsSaving] = useState(false);
  const [isSaved, setIsSaved] = useState(false);

  const clientData = clients.find((cl) => cl._id === client);

  function isValid(){
    const clientData = clients.find((cl) => cl._id === client);

    return !(
      clientData === null || clientData === undefined ||
      (clientData.config.containers && container === '') ||
      isNaN(weight) || weight <= 0 || material === '');
  }

  function onSaveReceived(){
    if (!isValid()) {
      return;
    }
    // Validate
    setIsSaving(true);
    saveReceived()
      .then(() => {
        setIsSaved(true);
      })
      .catch((ex) => {
        setIsSaving(false);
      });
  }

  function savedAcknowledged(){
    setIsSaved(false);
    setIsSaving(false);
    selectClient('');
    setWeight('');
    setContainer('');
  }

  return <div className="delivery-wrapper split-part split-view split-2 box mr-2">
    <div className="flex-col flex-grow">
      <div className="flex-5 flex-shrink-no">
        <Link to="/" className="absolute btn-cirlce color-default"><Close /></Link>
        <h2 className="text-center">Received Dross</h2>
      </div>
      <div className="flex-row flex-grow-no  flex-5 mb-2">
        <div className="flex-20">
          <label className="form-control input-group">
            <span>Client</span>
            <select disabled={isSaved} className="input" onChange={(e) => selectClient(e.target.value)} value={client}>
              <option value="">Select one</option>
              {clients.map((client) => <option key={client._id} value={client._id}>{client.name}</option>)}
            </select>
          </label>
        </div>
          { !isSaved ?
            <button
            onClick={onSaveReceived}
            className="btn btn-primary align-center"
            disabled={isSaving || !isValid()}
          >
            Complete
          </button> :
          <div className="box joi-box ml-2">
            <div className="mb-2">Completed</div>
            <button className="btn" onClick={savedAcknowledged}>OK</button>
          </div>
        } 
      </div>
      {client && (
        <div className="flex-row scroll-none">
          <div className="flex-20 scroll-y mr-2">
            <div className="box">
              <label className="block form-control input-group mb-2">
                <span>Material</span>
                <select disabled={isSaved} className="input" onChange={(e) => setMaterial(e.target.value)} value={material}>
                  <option value="">Select one</option>
                  {clientData && clientData.config.materials.map((material) =>(
                    <option key={material.id} value={material.id}>{material.name}</option>
                  ))}
                </select>
              </label>
              {clientData && clientData.config.containers && (
                <label className="block form-control input-group mb-2">
                  <span>Container</span>
                  <input disabled={isSaved} className="input" onChange={(e) => setContainer(e.target.value)} value={container} />
                </label>
              )}
              <label className="block form-control input-group">
                <span>Weight</span>
                <input disabled={isSaved} className="input" onChange={(e) => setWeight(e.target.value)} value={weight} />
              </label>
            </div>
          </div>
          <div className="flex-30">
          </div>
        </div>
      )}
    </div>
  </div>
}

ReceivePage.propTypes = {
  clients: PropTypes.array
};



export default connect(
  (state, ownProps) => {
    return {
      ...ownProps,
      clients: state.clients.clients
    };
  },
  (dispatch) => {
    return {
      hydrateClients: () => {
        dispatch(hydrateClients());
      }
    };
  }
)(ReceivePage);
