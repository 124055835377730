import { post, get, put } from '../utils/http.js';
import { baseApi } from '../config.js';

export const CYCLE_NEW = 'CYCLE_NEW';
export const CYCLE_SENT = 'CYCLE_SENT';
export const CYCLE_RESET = 'CYCLE_RESET';
export const CYCLE_VALUE = 'CYCLE_VALUE';
export const CYCLE_DROSS_ADD = 'CYCLE_DROSS_ADD';
export const CYCLE_DROSS_RM = 'CYCLE_DROSS_RM';
export const CYCLE_DROSS_VALUE = 'CYCLE_DROSS_VALUE';
export const CYCLE_SAVE_DATA = 'CYCLE_SAVE_DATA';
export const CYCLE_ALUMINIUM_ADD = 'CYCLE_ALUMINIUM_ADD';
export const CYCLE_ALUMINIUM_RM = 'CYCLE_ALUMINIUM_RM';

export const CYCLE_STAGES = {
  STAGE_1: 'STAGE_1',
  STAGE_2: 'STAGE_2',
  STAGE_3: 'STAGE_3',
  FINISHED: 'FINISHED',
};

export const saveStep = ({ next }) => {
  return (dispatch, getState) => {
    const { cycle } = getState();

    // If it already exists we do update
    if (cycle._id) {
      put(`${baseApi}/cycles/${cycle._id}`, {
        state: next,
        data: {
          oxygen: cycle.data.oxygen,
          oil: cycle.data.oil,
          cycletime: cycle.data.cycletime,
          notes: cycle.data.notes,
          aluminium: cycle.data.aluminium.filter((al) => al > 0), // only include aluminium over 0
        },
      }).then((data) => {
        dispatch({
          type: CYCLE_SAVE_DATA,
          data,
        });
      });
    } else {
      post(`${baseApi}/cycles`, {
        ...cycle,
        state: next,
      }).then((data) => {
        dispatch({
          type: CYCLE_SAVE_DATA,
          data,
        });
      });
    }
  };
};

export const getCycle = (cycleId) => {
  return (dispatch) => {
    get(`${baseApi}/cycles/${cycleId}`).then((data) => {
      dispatch({
        type: CYCLE_SAVE_DATA,
        data,
      });
    });
  };
};

export const setRegValue = ({ keys, value }) => {
  return {
    type: CYCLE_VALUE,
    data: {
      keys,
      value,
    },
  };
};

export const addDross = () => {
  return {
    type: CYCLE_DROSS_ADD,
  };
};
export const removeDross = () => {
  return {
    type: CYCLE_DROSS_RM,
  };
};

export const addAluminium = () => {
  return {
    type: CYCLE_ALUMINIUM_ADD,
  };
};
export const rmAluminium = (index) => {
  return {
    type: CYCLE_ALUMINIUM_RM,
    data: index,
  };
};

export const registerNew = (clientId) => {
  return (dispatch, getState) => {
    const { clients } = getState();
    const client = clients.clients.find((cl) => cl._id === clientId);

    dispatch({
      type: CYCLE_NEW,
      data: {
        config: client.config,
        clientId: clientId,
      },
    });
  };
};

export const registerSend = () => {
  // TODO: Send to server and show results..
  return { type: CYCLE_SENT };
};

export const registerReset = () => {
  return {
    type: CYCLE_RESET,
  };
};
