export function formatWeight(inp) {
  if (inp > 3000) {
    return `${(inp / 1000).toFixed(1)}t`;
  }
  return `${inp}kg`;
}

export function formatPercent(inp) {
  return `${(inp * 100).toFixed(1)}%`;
}
