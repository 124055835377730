import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Authentication from '../common/auth.js';
import { loginUser } from '../common/actions/user.js';
import history from '../common/utils/history.js';
import { Logo } from '../components/icons.js';

function LoginPage(props) {
  const { loginUser } = props;
  const [message, setMessage] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  function onChangeName(e) {
    setUsername(e.target.value);
  }
  function onChangePassword(e) {
    setPassword(e.target.value);
  }

  function onSubmit() {
    setMessage('');
    if (username !== '' && password !== '') {
      Authentication.login({ username, password })
        .then((data) => {
          return loginUser(data);
        })
        .then(() => {
          history.push('/');
        })
        .catch((ex) => {
          setMessage('Incorrect username or password.');
        });
    }
  }
  function onKeyUp(e) {
    if (e.keyCode === 13) {
      onSubmit();
    }
  }

  return (
    <div className="login-wrapper">
      <div className="login-logo-wrapper">
        <Logo className="login-logo" />
      </div>
      <div className="form-group">
        <label className="form-control">
          <span className="sr-only">Username</span>
          <input
            className="input input-rounded"
            type="text"
            placeholder="Username"
            value={username}
            onKeyUp={onKeyUp}
            onChange={onChangeName}
          />
        </label>
        <label className="form-control">
          <span className="sr-only">Password</span>
          <input
            className="input input-rounded"
            type="password"
            placeholder="Password"
            value={password}
            onKeyUp={onKeyUp}
            onChange={onChangePassword}
          />
        </label>
        <div className="login-button">
          <button
            disabled={username.length === 0 || password.length === 0}
            className="btn btn-primary btn-continue"
            onClick={onSubmit}
          >
            Login
          </button>
        </div>
        {message !== '' && <div className="text-center">{message}</div>}
      </div>
    </div>
  );
}

LoginPage.propTypes = {
  loginUser: PropTypes.func
};

export default connect(
  (state, ownProps) => {
    return ownProps;
  },
  (dispatch) => {
    return {
      loginUser: (data) => {
        dispatch(loginUser(data));
      }
    };
  }
)(LoginPage);
