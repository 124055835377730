export const filledPot = function(id, percent, width = 48, height = 12) {
  const widthPercent = percent * 47; // 47 is the viewBox
  return `
  <svg version="1.1" id="pot-${id}" width="${width}" height="${height}" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 49 13" style="enable-background:new 0 0 49 13;" xml:space="preserve">
  <style type="text/css">
  	#pot-${id} .st0 {fill:#73738B;}
  	#pot-${id} .st1 {clip-path:url(#pot-${id}-SVGID_2_);}
  	#pot-${id} .st2 {fill:#BCBAC1;}
  </style>
  <path class="st0" d="M49,2.5C49,1.12,47.88,0,46.5,0h-44C1.12,0,0,1.12,0,2.5c0,1.34,1.06,2.43,2.39,2.49l2.4,6.39
  	C5.15,12.35,6.09,13,7.12,13h34.75c1.04,0,1.98-0.65,2.34-1.62l2.4-6.39C47.94,4.93,49,3.84,49,2.5z M43.28,11.03
  	C43.06,11.61,42.5,12,41.88,12H7.12c-0.62,0-1.19-0.39-1.4-0.97L3.46,5h42.08L43.28,11.03z M46.5,4h-44C1.67,4,1,3.33,1,2.5
  	S1.67,1,2.5,1h44C47.33,1,48,1.67,48,2.5S47.33,4,46.5,4z"/>
  <g>
  	<defs>
  		<rect id="pot-${id}-SVGID_1_" x="1" y="1" width="${widthPercent}" height="11"/>
  	</defs>
  	<clipPath id="pot-${id}-SVGID_2_">
  		<use xlink:href="#pot-${id}-SVGID_1_"  style="overflow:visible;"/>
  	</clipPath>
  	<g class="st1">
  		<path class="st2" d="M46.5,1h-44C1.67,1,1,1.67,1,2.5S1.67,4,2.5,4h44C47.33,4,48,3.33,48,2.5S47.33,1,46.5,1z"/>
  		<path class="st2" d="M5.72,11.03C5.94,11.61,6.5,12,7.12,12h34.75c0.62,0,1.19-0.39,1.41-0.97L45.54,5H3.46L5.72,11.03z"/>
  	</g>
  </g>
  </svg>`;
};
