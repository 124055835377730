import React, { Component } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  setRegValue,
  addDross,
  removeDross,
  registerNew,
  getCycle,
  saveStep,
  addAluminium,
  rmAluminium,
  CYCLE_STAGES
} from '../common/actions/cycle.js';

import Register from './stages/register.js';
import Register2 from './stages/register2.js';
import Register3 from './stages/register3.js';
import RegisterFinished from './stages/finished.js';
import Receipt from '../receipt/receipt.js';
import RegisterHeader from './register-header.js';

class RegisterContainer extends Component {
  constructor(props) {
    super(props);

    this.nextStep = this.nextStep.bind(this);
  }

  static propTypes = {
    clientId: PropTypes.string,
    client: PropTypes.object,
    user: PropTypes.object,
    cycle: PropTypes.object,
    setRegValue: PropTypes.func,
    addDross: PropTypes.func,
    removeDross: PropTypes.func,
    addAluminium: PropTypes.func,
    rmAluminium: PropTypes.func
  };

  componentDidMount() {
    const { clientId, cycleId, cycle } = this.props;
    if (cycleId) {
      this.props.getCycle(cycleId);
    } else {
      this.props.registerNew(clientId);
    }
  }

  nextStep() {
    switch (this.props.cycle.state) {
      case CYCLE_STAGES.STAGE_1:
        {
          this.props.saveStep({ next: CYCLE_STAGES.STAGE_2 });
        }
        break;
      case CYCLE_STAGES.STAGE_2:
        {
          this.props.saveStep({ next: CYCLE_STAGES.STAGE_3 });
        }
        break;
      case CYCLE_STAGES.STAGE_3:
        {
          this.props.saveStep({ next: CYCLE_STAGES.FINISHED });
        }
        break;
      default: {
        console.log('next Step!!', this.props.cycle.state);
      }
    }
  }

  renderCurrentStep() {
    const {
      clientId,
      client,
      cycle,
      user,
      setRegValue,
      addDross,
      removeDross,
      addAluminium,
      rmAluminium
    } = this.props;

    switch (cycle.state) {
      case CYCLE_STAGES.STAGE_1:
        return (
          <Register
            clientId={clientId}
            client={client}
            cycle={cycle}
            setRegValue={setRegValue}
            addDross={addDross}
            removeDross={removeDross}
            nextStep={this.nextStep}
          ></Register>
        );
      case CYCLE_STAGES.STAGE_2:
        return (
          <Register2
            clientId={clientId}
            client={client}
            cycle={cycle}
            setRegValue={setRegValue}
            nextStep={this.nextStep}
          ></Register2>
        );
      case CYCLE_STAGES.STAGE_3:
        return (
          <Register3
            clientId={clientId}
            client={client}
            cycle={cycle}
            setRegValue={setRegValue}
            nextStep={this.nextStep}
            addAluminium={addAluminium}
            rmAluminium={rmAluminium}
          ></Register3>
        );
      case CYCLE_STAGES.FINISHED:
        return <RegisterFinished />;
      default:
        return <h2>Unknown state: {cycle.state}</h2>;
    }
  }

  render() {
    const { clientId, client, cycle, user, setRegValue, addDross, removeDross } = this.props;

    if (cycle.state === 'NONE') {
      return (
        <div className="split-view">
          <div className="split-part split-left">
            <p>melting data...</p>
          </div>
          <div className="split-part split-right split-504"></div>
        </div>
      );
    }

    return (
      <div className="register-container">
        <RegisterHeader clientId={clientId} client={client} currentStep="" />
        <div className="split-view split-2">
          <div className="split-part split-left">{this.renderCurrentStep()}</div>
          <div className="split-part split-right split-504">
            <Receipt clientId={clientId} client={client} cycle={cycle} />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  (state, ownProps) => {
    const { clientId, cycleId } = ownProps.match.params;
    const client = state.clients.clients.find((c) => c._id === clientId);

    return {
      ...ownProps,
      clientId: clientId,
      client: client,
      user: state.user,
      cycle: state.cycle,
      cycleId: cycleId
    };
  },
  (dispatch) => {
    return {
      setRegValue: (change) => {
        dispatch(setRegValue(change));
      },
      addDross: () => {
        dispatch(addDross());
      },
      removeDross: () => {
        dispatch(removeDross());
      },
      getCycle: (cycleId) => {
        dispatch(getCycle(cycleId));
      },
      registerNew: (clientId) => {
        dispatch(registerNew(clientId));
      },
      saveStep: (opt) => {
        dispatch(saveStep(opt));
      },
      addAluminium: () => {
        dispatch(addAluminium());
      },
      rmAluminium: (index) => {
        dispatch(rmAluminium(index));
      }
    };
  }
)(RegisterContainer);
