import { combineReducers } from 'redux';

import globals from './globals.js';
import user from './user.js';
import clients from './clients.js';
import cycle from './cycle.js';

export default combineReducers({
  globals,
  user,
  clients,
  cycle
});
