import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import history from '../common/utils/history.js';
import useDelivery from './use-delivery.js';
import { Close } from '../components/icons.js';

function DeliveryPage({ clients }){
  const {
    cycleOptions,
    cycles,
    client,
    selectClient,
    addCycle,
    removeCycle,
    getWeight,
    saveDelivery
  } = useDelivery();

  const [isSaving, setIsSaving] = useState(false);
  const [isSaved, setIsSaved] = useState(false);

  const selectedClient = clients.find((cl) => cl._id === client);

  function onSaveDelivery(){
    setIsSaving(true);
    saveDelivery()
      .then(() => {
        setIsSaved(true);
      })
      .catch((ex) => {
        setIsSaving(false);
      });
  }

  function savedAcknowledged(){
    setIsSaved(false);
    setIsSaving(false);
    selectClient('');
  }

  return <div className="delivery-wrapper split-part split-view split-2 box mr-2">
    <div className="flex-col flex-grow">
      <div className="flex-5 flex-shrink-no">
        <Link to="/" className="absolute btn-cirlce color-default"><Close /></Link>
        <h2 className="text-center">Deliver aluminium</h2>
      </div>
      <div className="flex-row flex-grow-no  flex-5 mb-2">
        <div className="flex-20">
          <label className="form-control input-group">
            <span>Client</span>
            <select disabled={isSaved} className="input" onChange={(e) => selectClient(e.target.value)} value={client}>
              <option value="">Select one</option>
              {clients.map((client) => <option key={client._id} value={client._id}>{client.name}</option>)}
            </select>
          </label>
        </div>
          <button
            onClick={onSaveDelivery}
            className="btn btn-primary align-center"
            disabled={isSaving || getWeight() === 0}
          >
            Complete
          </button>
      </div>
      <div className="flex-row scroll-none">
        <div className="flex-20 scroll-y mr-2">
          <table className="list-table select-table">
            <thead>
              <tr>
                <th>Lot</th>
                <th>Material</th>
                <th>Weight</th>
              </tr>
            </thead>
            <tbody>
              {cycleOptions.map((option) => {
                const isIncluded = cycles.includes(option._id);
                const className = isIncluded ? 'selected' : '';
                return <tr
                  key={option._id}
                  onClick={() => isSaved ? false : isIncluded ? removeCycle(option._id) : addCycle(option._id)}
                  className={className}
                >
                  <td>{option.data.lotnumber}</td>
                  <td>{option.data.material}</td>
                  <td>{option.data.aluminium.reduce((tot, al) => tot + al, 0)}</td>
                </tr>
              })}
            </tbody>
          </table>
        </div>
        <div className="flex-30">
          <div className="box joi-box">
            {isSaved ? 
              <>
                <div className="mb-2">Completed</div>
                <button className="btn" onClick={savedAcknowledged}>OK</button>
              </> :
              <>
                <span className="joi-box-header">Total</span>
                <p className="joi-box-content">{getWeight()} kg</p>
              </>
            }
          </div>
        </div>
      </div>
    </div>
  </div>
}

DeliveryPage.propTypes = {
  loggedin: PropTypes.bool,
  user: PropTypes.object,
  clients: PropTypes.array,
  onLogout: PropTypes.func
};



export default connect(
  (state, ownProps) => {
    return {
      ...ownProps,
      user: state.user,
      loggedin: state.user.loggedin,
      clients: state.clients.clients
    };
  },
  (dispatch) => {
    return {
      hydrateClients: () => {
        dispatch(hydrateClients());
      },
      onLogout() {
        dispatch(onLogout()).then(() => {
          history.push('/login');
        });
      }
    };
  }
)(DeliveryPage);
