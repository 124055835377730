import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import history from '../common/utils/history.js';
import { Switch, Route } from 'react-router-dom';

import { hydrateClients } from '../common/actions/clients.js';
import { onLogout } from '../common/actions/user.js';

import Dashboard from './dashboard.js';
import CycleList from './sections/cycles.js';
import DeliveryPage from '../delivery/delivery.js';
import ReceivePage from '../receive/receive.js';

function HomePage(props) {
  const { user, clients, onLogout } = props;

  return (
    <div className="home-wrapper split-view">
      <Switch>
        <Route path="/" exact>
          <Dashboard user={user} clients={clients} onLogout={onLogout} />
        </Route>
        <Route path="/delivery/in">
          <ReceivePage />
        </Route>
        <Route path="/delivery/out">
          <DeliveryPage />
        </Route>
      </Switch>
      <div className="split-part split-504 flex-col">
        <CycleList clients={clients} />
      </div>
    </div>
  );
}

HomePage.propTypes = {
  loggedin: PropTypes.bool,
  user: PropTypes.object,
  clients: PropTypes.array,
  onLogout: PropTypes.func
};

export default connect(
  (state, ownProps) => {
    return {
      ...ownProps,
      user: state.user,
      clients: state.clients.clients
    };
  },
  (dispatch) => {
    return {
      hydrateClients: () => {
        dispatch(hydrateClients());
      },
      onLogout() {
        dispatch(onLogout()).then(() => {
          history.push('/login');
        });
      }
    };
  }
)(HomePage);
