import React from 'react';
import PropTypes from 'prop-types';

function HeaderUser(props) {
  const { user } = props;

  const initials = user.name
    .split(' ')
    .map((n) => (n.length > 0 ? n[0] : ''))
    .join('');

  return (
    <div className="user-top">
      <div className="user-top-avatar">
        <div className="initials-avatar">{initials}</div>
      </div>
      <p>{user.name}</p>
    </div>
  );
}

HeaderUser.propTypes = {
  user: PropTypes.object,
};

export default HeaderUser;
