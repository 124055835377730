import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LineGraph from '../../components/charts/chart.js';

import { get } from '../../common/utils/http.js';
import { baseApi } from '../../common/config.js';
import range from '../../common/utils/range.js';
import { deepMerge, deepClone } from '../../common/utils/obj.js';

// TODO: Do it for real..
const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

function hexToRgbA(hex, opacity = 1) {
  var c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');
    return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + opacity + ')';
  }
  throw new Error('Bad Hex');
}

class Graph extends Component {
  static propTypes = {
    data: PropTypes.array,
    shown: PropTypes.array,
    daysinmonth: PropTypes.number
  };

  constructor(props) {
    super(props);

    this.onResize = this.onResize.bind(this);

    this.parentEl = React.createRef();

    this.state = {
      graphWidth: 200,
      graphHeight: 200
    };
  }

  getFontSize() {
    const windowWidth = window.innerWidth;
    if (windowWidth < 1100) {
      return 8;
    } else if (windowWidth < 1900) {
      return 10;
    }
    return 12;
  }

  componentDidMount() {
    this.onResize();
    window.addEventListener('resize', this.onResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  onResize() {
    if (!this.parentEl) {
      return;
    }
    const { offsetWidth, offsetHeight } = this.parentEl.current;
    this.setState({
      graphWidth: offsetWidth,
      graphHeight: offsetHeight
    });

  }

  render() {
    const { data, daysinmonth } = this.props;
    const { graphWidth, graphHeight } = this.state;

    const chartData = {
      labels: range(1, daysinmonth),
      padding: {
        left: 20,
        right: 20,
        top: 0,
        bottom: 0
      },
      datasets: [{
        backgroundColor: hexToRgbA('#ff7133', 0.3), //client.config.color,
        backgroundColorEnd: hexToRgbA('#ff7133', 0), //client.config.color,
        borderColor: '#ff7133', //client.config.color,
        data: data.map((item) => Number((item.accyield * 100).toFixed(1)))
      }]
    };

    const fontName = `${this.getFontSize()}px Arial`;

    return (
      <div className="graph-bottom" ref={this.parentEl}>
        <LineGraph data={chartData} height={graphHeight} width={graphWidth} fonts={fontName} minY={40} />
      </div>
    );
  }
}

export default Graph;
