import Auth from '../auth.js';

import { USER_LOGIN, USER_LOGOUT, USER_HYDRATE } from '../actions/user.js';

const initialState = function() {
  return {
    loggedin: false,
    name: 'User',
    role: 'user',
    companyId: '',
    avatar: '',
    company: { id: '', name: '' }
  };
};

const reducer = (state = initialState(), action) => {
  switch (action.type) {
    case USER_LOGIN:
      return {
        ...state,
        loggedin: true,
        name: action.data.name, // TODO: should get name
        role: action.data.role.toLowerCase(),
        avatar: action.data.avatar,
        companyId: action.data.companyId,
        company: action.data.company
      };
    case USER_LOGOUT:
      return {
        ...initialState()
      };
    default:
      return state;
  }
};

export default reducer;
