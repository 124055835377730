import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ExportIcon, ImportIcon } from '../components/icons.js';

import ClientList from './sections/clientlist.js';
import UserHome from './sections/user.js';
import Stats from './sections/stats.js';

export default function Dashboard(props) {
  const { user, clients, onLogout } = props;

  return <div className="split-part split-vertical" style={{ marginRight: '2rem' }}>
    <div className="split-portion split-h-490">
      <div className="split-part split-400" style={{ marginBottom: '2rem' }}>
        <UserHome user={user} onLogout={onLogout} />
      </div>
      <div className="split-part" style={{ padding: '0 0 2rem 2rem' }}>
        <ClientList clients={clients} />
        <div className="flex-row mt-2">
          <Link to="/delivery/in" className="box link-box ml-2">
            <ImportIcon className="link-box-icon" />
            <p className="link-box-content">Receive</p>
          </Link>
          <Link to="/delivery/out" className="box link-box ml-2">
            <ExportIcon className="link-box-icon" />
            <p className="link-box-content">Deliver</p>
          </Link>
        </div>
      </div>
    </div>
    <div className="split-portion split-h-490">
      <div className="split-part">
        <Stats clients={clients} />
      </div>
    </div>
  </div>

};

Dashboard.propTypes = {
  user: PropTypes.object,
  clients: PropTypes.array,
  onLogout: PropTypes.func
};