export const TOGGLE_LOGIN = 'TOGGLE_LOGIN';

export const openLoginSheet = () => {
  return (dispatch) => {
    dispatch({ type: TOGGLE_LOGIN, data: true });
  };
};

export const closeLoginSheet = () => {
  return (dispatch) => {
    dispatch({ type: TOGGLE_LOGIN, data: false });
  };
};
