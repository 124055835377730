export const HYDRATE_CLIENTS = 'HYDRATE_CLIENTS';
import { get } from '../utils/http.js';
import { baseApi } from '../config.js';

export const hydrateClients = () => {
  return (dispatch) => {
    return get(`${baseApi}/clients`).then((data) => {
      dispatch({
        type: HYDRATE_CLIENTS,
        data: data
      });
    });
  };
};
