import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Arrow } from '../components/icons.js';

function RegisterHeader(props) {
  const { client } = props;
  return (
    <div className="meltit-header register-header">
      <hr />
      <Link className="btn btn-outline btn-cirlce" to="/">
        <Arrow className="back-arrow" />
      </Link>
      <p className="btn btn-outline btn-primary">{client.name}</p>
      <hr />
      <div style={{ flex: '534px 0 0' }}></div>
    </div>
  );
}

RegisterHeader.propTypes = {
  client: PropTypes.object
};

export default RegisterHeader;
