/**
 * export const - Get a deep value from Object with path
 *
 * @param  {Object} obj      Object to get value from
 * @param  {string} path     dot separated path to value
 * @param  {Object} defaults default return if undefined
 * @return {Object}          Value of Object by path
 */
export const getObjValue = function(obj, path, defaults) {
  if (!obj) {
    return typeof defaults === 'undefined' ? obj : defaults;
  }
  if (path && typeof path === 'string') {
    return getObjValue(obj, path.split('.'), defaults);
  }
  if (path.length > 0) {
    return getObjValue(obj[path[0]], path.slice(1), defaults);
  }
  return obj;
};

/**
 * export const - Check if array deep in Object contains value
 *
 * @param  {Object} obj   Object to check on
 * @param  {string} path  Path to array
 * @param  {Object} value value that should be in array
 * @return {boolean}      True if array includes value
 */
export const contains = function(obj, path, value) {
  const realVal = getObjValue(obj, path);
  if (realVal instanceof Array) {
    return realVal.includes(value);
  }
  return false;
};

/**
 * export const - Check if value deep in Object is equal to value param
 *
 * @param  {Object} obj   Object to check on
 * @param  {string} path  Path to ceck value
 * @param  {Object} value Expected value
 * @return {boolean}      True if value equals value param
 */
export const equals = function(obj, path, value) {
  const realVal = getObjValue(obj, path);
  return realVal === value;
};

/**
 * export const - Update deep value in Object
 *
 * @param  {Object} obj   Object to update deep value in
 * @param  {string} path  Path to key to update
 * @param  {Object} value Value to update to
 */
export const updateValue = function(obj, path, value) {
  if (typeof path === 'string') {
    updateValue(obj, path.split('.'), value);
  } else if (path.length > 1) {
    updateValue(obj[path[0]], path.slice(1), value);
  } else if (path.length === 1) {
    if (obj[path] instanceof Array && typeof value === 'string') {
      obj[path] = value.split(',');
    } else {
      obj[path] = value;
    }
  }
};

/**
 * Compares two variables if they are equal (does deep equality)
 * @param {any} x
 * @param {any} y
 * @param {array} ignoreProp an array of keys / names we don't want to compare
 * @returns {boolean} Returns true|false depending on if the variables are equal
 */
export const deepEqual = function(x, y, ignoreProp = []) {
  if (x === y) {
    return true;
  } else if (typeof x === 'object' && x != null && (typeof y === 'object' && y !== null)) {
    if (Object.keys(x).length !== Object.keys(y).length) {
      return false;
    }

    for (let prop in x) {
      if (ignoreProp.includes(prop)) {
        // Skip checking this prop
      } else if (y.hasOwnProperty(prop)) {
        if (!deepEqual(x[prop], y[prop], ignoreProp)) {
          return false;
        }
      } else {
        return false;
      }
    }
    return true;
  } else {
    return false;
  }
};

/**
 * Clones object or arrays value by value
 * @param {any} aObject Object or array to be copied from
 * @param {Object} options
 * @param {Array} options.skip A list of strings of keys to skip (regardless of path)
 * @param {Array<String>} options.skipSpecific A list of strings that will skip keys matching the path specified.
 * Note: it cannot target specific elements in an array.
 * @returns {any} Returns a new object identical to the original
 */
export const deepClone = function(aObject, options = {}, path = '') {
  if (!aObject) {
    return aObject;
  }

  let value;
  let bObject = Array.isArray(aObject) ? [] : {};
  for (const key in aObject) {
    let newPath = path;
    if (!Array.isArray(aObject)) {
      newPath = path.length > 0 ? `${path}.${key}` : `${key}`;
    }

    if (options.skip && options.skip.includes(key)) {
      continue;
    }
    if (options.skipSpecific && options.skipSpecific.includes(newPath)) {
      continue;
    }

    value = aObject[key];

    bObject[key] = typeof value === 'object' ? deepClone(value, options, newPath) : value;
  }

  return bObject;
};

/**
 * Check if object is empty
 * @param {object} obj to check
 * @returns {boolean} true for empty object
 */
export const isEmpty = function(obj) {
  return (
    obj === null ||
    obj === undefined ||
    (Object.keys(obj).length === 0 && obj.constructor === Object)
  );
};

/**
 * Deep merge two objects
 * If there are arrays, the first one will be overwritten by the second
 * @param {object} org original object
 * @param {object} obj overwriting object
 * @returns {object} New object out of the two
 */
export const deepMerge = function(org, obj) {
  if (obj instanceof Array) {
    return [...obj];
  }
  const item = {
    ...org,
    ...obj
  };

  if (obj) {
    Object.keys(obj).map((key) => {
      if (obj[key] instanceof Array || typeof obj[key] === 'object') {
        item[key] = deepMerge(org[key] || {}, obj[key]);
      }
    });
  }

  return item;
};

/**
 * Will take any kind of object, string, number etc and return an Array.
 * The array will be popoulated with the values in the object, the string value
 * or the number value. If it is something it cannot convert it will return an
 * empty array.
 * @param {any} obj the object to be converted to array
 * @returns {array} The converted array
 */
export const convertToArray = function(obj) {
  if (obj instanceof Array) {
    return obj;
  } else if (typeof obj === 'number' || typeof obj === 'string') {
    return [obj];
  } else if (typeof obj === 'object' && obj !== null) {
    return Object.keys(obj).map((key) => obj[key]);
  } else {
    return [];
  }
};

/**
 * Creates an object from an array, and keys them by selected key
 * @param {array} arr The array to be keyd
 * @param {string} key The key to use in array values
 * @returns {object} The object created
 */
export const keyBy = function(arr, key) {
  return arr.reduce((sum, val) => {
    return {
      ...sum,
      [val[key]]: val
    };
  }, {});
};
