export let baseUrl = 'http://localhost:1234/';
export let baseApi = 'http://localhost:4040/api/';

const appConfigEl = document.querySelector('#app-config');

try {
  const inner = appConfigEl.innerHTML;
  const data = JSON.parse(inner);
  baseUrl = data.siteurl;
  baseApi = data.apiurl;
}
catch(ex) {
  console.log('Failed to parse app config');
  console.log(ex);
}
