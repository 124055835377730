import { TOGGLE_LOGIN } from "../actions/globals.js";

const initialState = {
  loginSheetOpen: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_LOGIN:
      return {
        ...state,
        loginSheetOpen: action.data
      };
    default:
      return state;
  }
};

export default reducer;
