import React from 'react';
import PropTypes from 'prop-types';

import HeaderUser from '../../components/header/header-user.js';

const WEEKDAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const MONTHS = ['JAN', 'FEB', 'MAR', 'APR', 'MAI', 'JUN', 'JUL', 'AUG', 'SEP', 'OKT', 'NOV', 'DES'];

function UserHome(props) {
  const { user, onLogout } = props;

  const now = new Date();
  const weekday = now.getDay();
  const month = now.getMonth();
  const date = now.getDate();

  return (
    <section className="user-banner  box">
      <div className="user-top-row">
        <div className="user-top-date">
          <span className="user-date-weekday">{WEEKDAYS[weekday]}</span>
          <span className="user-date-date">
            {date} {MONTHS[month]}
          </span>
        </div>
        <div className="user-top-company">{user.company.name}</div>
      </div>
      <div className="user-profile">
        <HeaderUser user={user} />
      </div>
      <div className="user-actions">
        <button onClick={onLogout} type="button" className="btn">
          Log out
        </button>
      </div>
    </section>
  );
}

UserHome.propTypes = {
  user: PropTypes.object,
  onLogout: PropTypes.func,
};

export default UserHome;
