/* eslint-disable prefer-destructuring */
import { baseApi } from './config.js';
import { post, get } from './utils/http.js';
import getStorage from './utils/storage.js';

const STORAGE_NAME = 'authorizationData';
const storage = getStorage();

const user = {
  isAuth: false,
};

const decodeToken = (token) => {
  let header;
  let claim;
  let signature;

  token = token || '';
  const parts = token.split('.');

  if (parts.length === 3) {
    [header, claim, signature] = parts;
    header = JSON.parse(decodeURIComponent(escape(atob(header))));
    claim = JSON.parse(decodeURIComponent(escape(atob(claim))));
  }

  return {
    header,
    claim,
    signature,
  };
};

const setLoginState = (token) => {
  localStorage.setItem(STORAGE_NAME, token);
  user.isAuth = true;
};

const login = ({ username, password }) => {
  return post(`${baseApi}/auth/login`, { username, password }).then((response) => {
    if (response.token) {
      setLoginState(response.token);

      return response.user;
    }

    throw response.error;
  });
};

const logOut = () => {
  return new Promise((resolve, reject) => {
    storage.removeItem(STORAGE_NAME);
    user.isAuth = false;

    resolve();
  });
};

const getAuthData = () => {
  const authData = storage.getItem(STORAGE_NAME);

  let userData = {};

  if (authData) {
    const decodedTokenJson = decodeToken(authData);

    user.isAuth = true;

    userData = {
      ...decodedTokenJson.claim,
    };
  }

  // Expired token
  if (user.isAuth && userData.expires * 1000 < Date.now()) {
    logOut();
    return {
      exp: true,
    };
  }

  return {
    exp: false,
    isAuth: user.isAuth,
    ...userData,
  };
};

const checkUserLogin = () => {
  if (!getAuthData()) {
    return Promise.reject();
  }
  return get(`${baseApi}/users/self`).then((data) => {
    return data;
  });
};

const getToken = () => {
  const authData = storage.getItem(STORAGE_NAME);

  if (authData) {
    return authData;
  }

  return null;
};

const authObj = {
  login,
  logOut,
  getAuthData,
  user,
  getToken,
  checkUserLogin,
};

export default authObj;
