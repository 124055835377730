const history = require('history');

/* eslint-disable import/no-mutable-exports */
let routeHistory;

if (typeof document === 'undefined') {
  routeHistory = history.createMemoryHistory();
} else {
  routeHistory = history.createBrowserHistory();
}

export default routeHistory;
