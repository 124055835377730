const range = function (from = 0, to = 100, every = 1, includeLast = false) {
  const arr = [];
  let i = from;
  for (; i <= to; i += every) {
    arr.push(i);
  }
  return arr;
};

export default range;
