import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import { Switch, Route, Redirect } from 'react-router-dom';

import Home from './home/home.js';
import NotFound from './notfound/notfound.js';
import Register from './register/register.js';

class DefaultLayout extends Component {
  static propTypes = {
    user: PropTypes.object,
  };

  render() {
    const { user } = this.props;

    if (!user.loggedin) {
      return <Redirect to="/login" />;
    }

    return (
      <div className="main-content">
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/delivery/">
            <Home />
          </Route>
          <Route
            path="/register/:clientId"
            exact={true}
            render={(props) => <Register {...props} />}
          />
          <Route
            path="/register/:clientId/:cycleId"
            exact={true}
            render={(props) => <Register {...props} />}
          />
          <Route path="/404">
            <NotFound />
          </Route>
        </Switch>
      </div>
    );
  }
}

export default connect(
  (state, ownProps) => {
    return {
      ...ownProps,
      user: state.user,
    };
  },
  (dispatch) => {
    return {};
  }
)(DefaultLayout);
