import {
  CYCLE_NEW,
  CYCLE_DROSS_ADD,
  CYCLE_DROSS_RM,
  CYCLE_ALUMINIUM_ADD,
  CYCLE_ALUMINIUM_RM,
  CYCLE_SENT,
  CYCLE_RESET,
  CYCLE_VALUE,
  CYCLE_STAGES,
  CYCLE_SAVE_DATA
} from '../actions/cycle.js';

function createCycleData(lotnumber, config) {
  const data = {
    dross: [],
    lotnumber: lotnumber
  };
  if (config.salt) {
    data.salt = 0;
  }
  if (config.notes) {
    data.notes = '';
  }
  if (config.containers) {
    data.dross.push({
      weight: 0,
      container: ''
    });
  } else {
    data.dross.push({
      weight: 0
    });
  }
  return data;
}

function createCycleState(data) {
  return {
    data: createCycleData(data.lotnumber, data.config),
    clientId: data.clientId,
    state: CYCLE_STAGES.STAGE_1
  };
}

const initialState = () => {
  return {
    data: {},
    clientId: '',
    state: 'NONE' // TODO have shared
  };
};

const updateDrossValue = function(dross, data) {
  return dross.map((item) => {
    if (item.id === data.id) {
      return {
        ...item,
        [data.key]: data.value
      };
    }
    return item;
  });
};

const changeValue = function(data, keys, value) {
  if (keys.length === 0) {
    return value;
  }

  if (data instanceof Array) {
    return [
      ...data.slice(0, keys[0]),
      changeValue(data[keys[0]], keys.slice(1), value),
      ...data.slice(keys[0] + 1)
    ];
  }
  if (keys.length > 1) {
    return {
      ...data,
      [keys[0]]: changeValue(data[keys[0]], keys.slice(1), value)
    };
  }

  return {
    ...data,
    [keys[0]]: value
  };
};

const reducer = (state = initialState(), action) => {
  switch (action.type) {
    case CYCLE_NEW:
      return createCycleState(action.data);
    case CYCLE_SAVE_DATA:
      return {
        ...state,
        ...action.data
      };
    case CYCLE_VALUE:
      return {
        ...state,
        data: {
          ...state.data,
          ...changeValue(state.data, action.data.keys, action.data.value)
        }
      };
    case CYCLE_DROSS_ADD:
      return {
        ...state,
        data: {
          ...state.data,
          dross: [
            ...state.data.dross,
            {
              container: '',
              weight: 0
            }
          ]
        }
      };
    case CYCLE_DROSS_RM:
      return {
        ...state,
        data: {
          ...state.data,
          dross: [state.data.dross[0]]
        }
      };
    case CYCLE_ALUMINIUM_ADD:
      return {
        ...state,
        data: {
          ...state.data,
          aluminium: [...state.data.aluminium, 0]
        }
      };
    case CYCLE_ALUMINIUM_RM: {
      return {
        ...state,
        data: {
          ...state.data,
          aluminium: [
            ...state.data.aluminium.slice(0, action.data),
            ...state.data.aluminium.slice(action.data + 1)
          ]
        }
      };
    }
    case CYCLE_SENT:
      return {
        ...state,
        state: 'FINISHED'
      };
    case CYCLE_RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default reducer;
